import React from 'react';

import CockpitImage from '../components/CockpitImage';
import Layout from '../components/layout';
import Legend from '../components/Legend';
import LegendMetadata from '../components/LegendMetadata';
import Quote from '../components/Quote';
import WhiteLogo from '../components/WhiteLogo';
import SEO from '../components/seo';

export default () => (
  <Layout className="uk-background-secondary">
    <SEO
      title="Bienvenido"
      keywords={[
        'escuela de aviacion',
        'piloto privado',
        'ciac',
        'apa',
        'airline pilot academy',
        'aviacion',
        'cochabamba',
        'bolivia',
        'piloto cochabamba',
      ]}
    />
    <div className="uk-section-default">
      <div id="home-skies-section" className="uk-section uk-light uk-background-cover">
        <div className="uk-container">
          <Legend />
          <LegendMetadata />
        </div>
      </div>
      <Quote />
    </div>
    <div id="quienes-somos" className="uk-section-secondary">
      <div className="uk-container uk-padding">
        <h2>Qui&eacute;nes somos</h2>
        <hr/>
        <div className="uk-grid-match uk-child-width-1-3@m" uk-grid="true">
          <div>
            <p>
              Airline Pilot Academy (APA) es un centro de instrucci&oacute;n de aviaci&oacute;n civil CIAC TIPO 3 (CCIAC-141-05-027) con base en la ciudad de Cochabamba, Bolivia.
              <br/>
              <br/>
              Iniciamos operaciones en octubre de 2012 y desde entonces hemos formado responsablemente decenas de pilotos profesionales.
            </p>
          </div>
          <div>
            <p>
              Nuestra misi&oacute;n es formar pilotos para aerolíneas con la mejor instrucci&oacute;n y entrenamiento, haciendo de la seguridad operacional nuestra prioridad. 
              <br/>
              <br/>
              Para mantener la seguridad operacional como una prioridad, fomentamos la cultura de reporte de peligros, esta es una herramienta primordial para la gestión de riesgos y para la implementación y mantenimiento efectivo del Sistema de Gestión de Seguridad Operacional SMS.
              <br/>
              <br/>
              Reporte aquí: <a href="https://wa.link/17fxtm" target="_blank">Reporte de peligro</a>
              <br/>
              <br/>
              Trabajamos con la visi&oacute;n de ser el centro de instrucci&oacute;n y entrenamiento de aviaci&oacute;n civil por excelencia en el pa&iacute;s.
            </p>
          </div>
          <div>
            <CockpitImage />
          </div>
        </div>
        <br/>
      </div>
    </div>
    <div className="uk-section-secondary">
      <div className="uk-container uk-padding uk-text-center" style={{ opacity: 0.5 }}>
        <WhiteLogo />
      </div>
    </div>
  </Layout>
);
