import React, { Component } from 'react';

export default class Legend extends Component {
  componentDidMount() {
    const ScrollReveal = require('scrollreveal').default;

    this.scrollreveal = ScrollReveal();
    this.scrollreveal.reveal(this.el, {
      reset: true,
      origin: 'right',
      duration: 1000,
      delay: 200,
      distance: '100px',
      scale: 1,
      easing: 'ease',
    });
  }

  componentWillUnmount() {
    this.scrollreveal.clean(this.el);
  }

  render() {
    return (
      <div className="legend" ref={el => (this.el = el)}>
        <h1>
          Ven y <b>aprende a volar</b>
          <br />
          con nosotros
        </h1>
      </div>
    );
  }
}
