import React, { Component } from 'react';

export default class LegendMetadata extends Component {
  componentDidMount() {
    const ScrollReveal = require('scrollreveal').default;

    this.scrollreveal = ScrollReveal();
    this.scrollreveal.reveal(this.el, {
      reset: true,
      origin: 'left',
      duration: 800,
      delay: 300,
      distance: '75px',
      scale: 1,
      easing: 'ease',
    });
  }

  componentWillUnmount() {
    this.scrollreveal.clean(this.el);
  }

  render() {
    return (
      <div className="legend-metadata" ref={el => (this.el = el)}>
        <p className="uk-inline-block">Centro de Instruccion de Aviacion Civil "CCIAC Tipo 3"</p>
        <br/>
        <p className="uk-inline-block">CCIAC-141-05-027</p>
      </div>
    );
  }
}
