import React, { Component } from 'react';

export default class Quote extends Component {
  componentDidMount() {
    const ScrollReveal = require('scrollreveal').default;

    this.scrollreveal = ScrollReveal();
    this.scrollreveal.reveal(this.el, {
      duration: 1000,
      distance: '100px',
      origin: 'top',
    });
  }

  componentWillUnmount() {
    this.scrollreveal.clean(this.el);
  }

  render() {
    return (
      <div className="uk-background-secondary uk-text-center quote">
        <div ref={el => (this.el = el)}>
          <p className="uk-margin-remove">El motor es el coraz&oacute;n de la aeronave, pero el piloto es su alma</p>
          <p className="uk-margin-remove"><i>Sir Walter Raleigh</i></p>
        </div>
      </div>
    );
  }
}
